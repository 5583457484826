<script>
import { VueMaskDirective as mask } from 'v-mask'
import PrintA4 from "@/components/print/defaults/PrintA4"

export default {
  components: {PrintA4},
  nome: 'RelatoriosPrintView',
  props: {
    data: {required: true}
  },
  directives: {mask},
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
  },
  mounted () {
  },
  activated () {
  },
  created () {
  },
  deactivated () {
  },
  watch: {},
  methods: {
  }
}
</script>

<template>
  <print-a4 :is-loading="isLoading">
    <div v-if="data && data.template" v-html="data.template"></div>
  </print-a4>
</template>
